import { apiAdmin } from "./api";

export const fetchNotifacations = async (page,per_page) => {
  try {
    const response = page && per_page ? await apiAdmin.get(`/api/getNotificationsForUser?per_page=${per_page}&page=${page}`) : await apiAdmin.get(`/api/getNotificationsForUser`);
    return response.data;
  } catch (error) {
    console.log("fetch notifacations err : ", error);
  }
};

export const markAsRead = async (notification_id) => {
  try {
    const response = await apiAdmin.get(`/api/markAsRead?notification_id=${notification_id}`);
    return response.data;
  } catch (error) {
    console.log("mark notifacation as read err : ", error);
  }
};