import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import { addCar } from "../Api/Cars";
import { laundryByAdmin } from "../Api/Laundry";
import { isSuper } from "../Api/api";
import Skeleton from "react-loading-skeleton";
import { fetchDrivers } from "../Api/Drivers";
import CustomSelect from "../Components/CustomSelect";
import { useDashboard } from "../Context/DashboardContext";

function AddNewCar() {
  const { t } = useTranslation();
  const { selectedLanguage } = useDashboard();
  const laundryId = localStorage.getItem("laundryId");
  const [laundries, setLaundries] = useState([]);
  const [laundriesLoading, setLaundriesLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [driversLoading, setDriversLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    laundryId: laundryId || "",
    driverId: "",
    carNumber: "",
  });
  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const getLaundriesData = async () => {
    try {
      setLaundriesLoading(true);
      const response = await laundryByAdmin(0);
      console.log(response.data);
      setLaundries(response.data.laundries);
    } catch (err) {
      console.error("get laundry data err : ", err);
    } finally {
      setLaundriesLoading(false);
    }
  };
  const getDriversData = async () => {
    try {
      setDriversLoading(true);
      const response = await fetchDrivers(0);
      console.log(response);
      setDrivers(response.data);
    } catch (err) {
      console.error("get drivers data err : ", err);
    } finally {
      setDriversLoading(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("laundry_id", data.laundryId);
    formData.append("driver_id", data.driverId);
    formData.append("number_car", data.carNumber);
    try {
      setLoading(true);
      const response = await addCar(formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/cars";
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("add car err : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    isSuper && getLaundriesData(0);
    getDriversData(0);
  }, []);
  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <div className="font-[500]">{t("Add New Car")}</div>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          {isSuper && (
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="laundry"
              >
                {t("Laundry")} <span className="text-red-600">*</span> :
              </label>
              {laundriesLoading ? (
                <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
              ) : (
                <CustomSelect
                  value={data.laundryId}
                  id="laundry"
                  name="laundryId"
                  onChange={changeHandler}
                  className={`w-full`}
                  children={
                    <>
                      <option disabled value="">
                        {t("Select laundry")}
                      </option>
                      {laundries.map((laundry) => (
                        <option
                          className="text-MainText bg-white"
                          key={laundry?.laundry?.id}
                          value={laundry?.laundry?.id}
                        >
                          {selectedLanguage === "Arabic"
                            ? laundry?.laundry?.name_ar
                            : laundry?.laundry?.name_en}
                        </option>
                      ))}
                    </>
                  }
                />
              )}
            </div>
          )}
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="drivers"
            >
              {t("Driver")} <span className="text-red-600">*</span> :
            </label>
            {driversLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <CustomSelect
                value={data.driverId}
                name="driverId"
                onChange={changeHandler}
                className={`w-full`}
                id="drivers"
                children={
                  <>
                    <option disabled value="">
                      {t("Select driver")}
                    </option>
                    {drivers.map((driver) => (
                      <option
                        className="text-MainText bg-white"
                        key={driver.id}
                        value={driver.id}
                      >
                        {driver?.user?.name}
                      </option>
                    ))}
                  </>
                }
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="carNumber"
          >
            {t("Car number")} <span className="text-red-600">*</span> :
          </label>
          <input
            type="number"
            value={data.carNumber}
            name="carNumber"
            onChange={changeHandler}
            id="carNumber"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
            placeholder={t("Car number")}
          />
        </div>
      </div>
      <button
        type="submit"
        onClick={submitHandler}
        className={`${
          loading ? "cursor-default pointer-events-none" : "cursor-pointer"
        } min-w-[81.38px] min-h-[38.34px] py-[7.5px] px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          t("Add")
        )}
      </button>
    </form>
  );
}

export default AddNewCar;
