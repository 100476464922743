import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { FaChartBar, FaShoppingCart, FaTruck, FaTshirt } from "react-icons/fa";
import {
  MdIron,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { usePopup } from "../Context/PopupContext";
import { HiOutlineUsers } from "react-icons/hi2";
import { FaPowerOff } from "react-icons/fa";
import { TbWashMachine } from "react-icons/tb";
import { AiOutlineClose } from "react-icons/ai";
import { FaDollarSign } from "react-icons/fa";
import { GrLanguage, GrUserAdmin, GrUserWorker } from "react-icons/gr";
import { RiAdvertisementLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import logo from "../Assets/Imgs/logo.png";
import { isSuper } from "../Api/api";
import { IoIosNotificationsOutline } from "react-icons/io";
import SidebarLinkItem from "./SidebarLinkItem";
import { IoNewspaperSharp } from "react-icons/io5";
import { useDashboard } from "../Context/DashboardContext";

function DashboardSidebar({ mob }) {
  const { t } = useTranslation();
  const { id, itemId, typeId, serviceId } = useParams();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const {
    sideBarOpen,
    setSideBarOpen,
    setMakeDecisionOpen,
    setAction,
    setDashboardSideBarOpen,
    setLanguageOpen,
  } = usePopup();
  const { selectedLanguage } = useDashboard();
  const location = useLocation();
  function isActive(path) {
    return location.pathname === path;
  }
  return (
    <div className="sticky left-0 top-0 overflow-y-auto h-screen z-30 bg-gradient-to-b from-[#78bcd0] to-[#00238c]">
      <div
        className={`${
          !mob ? (sideBarOpen ? "w-[200px]" : "w-[38px]") : "w-full"
        } relative h-full text-white duration-300`}
      >
        {mob && (
          <div
            onClick={() => setDashboardSideBarOpen(false)}
            className={`${
              selectedLanguage === "Arabic" ? "left-0" : "right-0"
            } my-1 absolute top-0 cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300 p-2 rounded-full bg-[#e2e2e2] hover:bg-[#c8c8c8]`}
          >
            <AiOutlineClose />
          </div>
        )}
        <nav>
          <h1
            className={`${
              !mob ? (sideBarOpen ? "block" : "invisible") : null
            } min-w-[200px] w-full text-center text-white font-bold text-[18px] py-1 flex md:justify-center items-center gap-1`}
          >
            <div className="w-[50px] h-[50px]">
              <img className="w-full h-full" src={logo} alt="" />
            </div>
            {t("Dobe Pro")}
          </h1>
          <SidebarLinkItem
            to={"/dashboard"}
            active={isActive("/dashboard")}
            icon={<FaChartBar />}
            content={t("Dashboard")}
            mob={mob}
          />

          {isSuper && (
            <SidebarLinkItem
              to={"laundries"}
              active={
                isActive("/dashboard/laundries") ||
                isActive(`/dashboard/laundries/${id}`) ||
                isActive(`laundries/addNewLaundry`) ||
                isActive(`laundries/${id}/updateLaundry`)
              }
              icon={<TbWashMachine />}
              content={t("Laundries")}
              mob={mob}
            />
          )}
          {isSuper && (
            <SidebarLinkItem
              to={"admins"}
              active={
                isActive("/dashboard/admins") ||
                isActive("/dashboard/addNewAdmin")
              }
              icon={<GrUserAdmin />}
              content={t("Admins")}
              mob={mob}
            />
          )}
          <SidebarLinkItem
            to={"users"}
            active={
              isActive("/dashboard/users") || isActive(`/dashboard/users/${id}`)
            }
            icon={<HiOutlineUsers />}
            content={t("Customers")}
            mob={mob}
          />
          <SidebarLinkItem
            to={"drivers"}
            active={
              isActive("/dashboard/drivers") ||
              isActive(`/dashboard/drivers/${id}`) ||
              isActive("/dashboard/addNewDriver")
            }
            icon={<GrUserWorker />}
            content={t("Drivers")}
            mob={mob}
          />

          <SidebarLinkItem
            to={"cars"}
            active={
              isActive("/dashboard/cars") ||
              isActive(`/dashboard/cars/${id}`) ||
              isActive(`/dashboard/cars/addNewCar`) ||
              isActive(`/dashboard/cars/${id}/updateCar`)
            }
            icon={<FaTruck />}
            content={t("Cars")}
            mob={mob}
          />
          <SidebarLinkItem
            to={"orders"}
            active={
              isActive("/dashboard/orders") ||
              isActive(`/dashboard/orders/${id}`) ||
              isActive(`/dashboard/orders/addNewOrder`) ||
              isActive(`/dashboard/orders/${id}/updateOrder`)
            }
            icon={<FaDollarSign />}
            content={t("Orders")}
            mob={mob}
          />
          {!isSuper && (
            <SidebarLinkItem
              to={"products"}
              active={
                isActive("/dashboard/products") ||
                isActive(`/dashboard/products/${itemId}/${typeId}/${serviceId}`)
              }
              icon={<FaShoppingCart />}
              content={t("Products")}
              mob={mob}
            />
          )}
          {isSuper && (
            <SidebarLinkItem
              to={"items"}
              active={
                isActive("/dashboard/items") ||
                isActive("/dashboard/items/addNewItem") ||
                isActive(`/dashboard/items/${id}/updateItem`)
              }
              icon={<FaTshirt />}
              content={t("Items")}
              mob={mob}
            />
          )}
          {isSuper && (
            <SidebarLinkItem
              to={"services"}
              active={
                isActive("/dashboard/services") ||
                isActive("/dashboard/items/addNewٍService") ||
                isActive(`/dashboard/services/${id}/updateService`)
              }
              icon={<MdIron />}
              content={t("Services")}
              mob={mob}
            />
          )}
          <SidebarLinkItem
            to={"notifications"}
            active={isActive("/dashboard/notifications")}
            icon={<IoIosNotificationsOutline />}
            content={t("Notifactions")}
            mob={mob}
          />
          <SidebarLinkItem
            to={"advertisements"}
            active={
              isActive("/dashboard/advertisements") ||
              isActive("/dashboard/addNewAdvertisement") ||
              isActive(`/dashboard/${id}/updateAdvertisement`)
            }
            icon={<RiAdvertisementLine />}
            content={t("Advertisements")}
            mob={mob}
          />
          <SidebarLinkItem
            to={"reports"}
            active={isActive("/dashboard/reports")}
            icon={<IoNewspaperSharp />}
            content={t("Reports")}
            mob={mob}
          />
          <div
            onClick={() => {
              setLanguageOpen(true);
            }}
            className={`flex items-center gap-2 w-full h-full cursor-pointer p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]`}
          >
            <GrLanguage />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Language")}
            </h1>
          </div>
          <div
            onClick={() => {
              setMakeDecisionOpen(true);
              setAction("logout");
            }}
            className={`flex items-center gap-2 w-full h-full cursor-pointer p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]`}
          >
            <FaPowerOff />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Logout")}
            </h1>
          </div>
          {!mob && (
            <div>
              {lng === "English" ? (
                sideBarOpen ? (
                  <div
                    onClick={() => setSideBarOpen(false)}
                    className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                  >
                    <AiOutlineClose />
                    <h1
                      className={`${
                        !mob ? (sideBarOpen ? "block" : "hidden") : null
                      }`}
                    >
                      {t("Hide")}
                    </h1>
                  </div>
                ) : (
                  <div
                    onClick={() => setSideBarOpen(true)}
                    className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                  >
                    <MdOutlineArrowForwardIos className="group-hover:translate-x-1 duration-300" />
                  </div>
                )
              ) : sideBarOpen ? (
                <div
                  onClick={() => setSideBarOpen(false)}
                  className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                >
                  <AiOutlineClose />
                  <h1
                    className={`${
                      !mob ? (sideBarOpen ? "block" : "hidden") : null
                    }`}
                  >
                    {t("Hide")}
                  </h1>
                </div>
              ) : (
                <div
                  onClick={() => setSideBarOpen(true)}
                  className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                >
                  <MdOutlineArrowBackIos className="group-hover:-translate-x-1 duration-300" />
                </div>
              )}
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}

export default DashboardSidebar;

