import React, { useEffect, useState } from "react";
import { useDashboard } from "../Context/DashboardContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { isSuper } from "../Api/api";
import { addAddress, addOrder } from "../Api/Orders";
import Skeleton from "react-loading-skeleton";
import { fetchLaundryItems } from "../Api/Items";
import { fetchServices } from "../Api/Services";
import CustomSelect from "../Components/CustomSelect";

function AddNewOrder() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { laundries, getLaundriesData, selectedLanguage } = useDashboard();
  const laundryId = localStorage.getItem("laundryId");
  const addressId = localStorage.getItem("laundryAddressId");
  const [showAddress, setShowAddress] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [selectedLaundryId, setSelectedLaundryId] = useState(laundryId || "");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    laundryId: laundryId || selectedLaundryId,
    addressId: addressId || "",
    items: [],
    orderTypeId: 2,
    date: "",
    time: "",
    note: "",
    name: "",
    email: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const [address, setAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    fullName: "",
    city: "",
    country: "",
    postCode: "",
    contactNumber: "",
    address: "",
    lat: "",
    lng: "",
  });

  const changeAddressHandler = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const orderData = {
      laundry_id: data.laundryId || laundryId,
      address_id: data.addressId || undefined,
      order_type_id: data.orderTypeId,
      name: data.name,
      email: data.email,
      note: data.note,
      // pickup_time: `${data.date} ${data.time}:00`,
      ids: data.items.flatMap((item) =>
        item.services.map((service) => ({
          item_id: item.laundry_item_id,
          quantity: item.quantity,
          service_id: service.service_id,
        }))
      ),
    };

    try {
      setLoading(true);
      const response = await addOrder(orderData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/orders";
      }
    } catch (err) {
      console.error("add new order err : ", err);
    } finally {
      setLoading(false);
    }
  };
  const [addressLoading, setAddressLoading] = useState(false);
  const submitAddressHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("address_line_1", address.addressLine1);
    formData.append("address_line_2", address.addressLine2);
    formData.append("full_name", address.fullName);
    formData.append("city", address.city);
    formData.append("country", address.country);
    formData.append("postcode", address.addressLine1);
    formData.append("contact_number", address.contactNumber);
    formData.append("address", address.address);
    formData.append("lat", address.lat);
    formData.append("lng", address.lng);

    try {
      setAddressLoading(true);
      const response = await addAddress(formData);
      console.log(response);
      if (response.success === true) {
        localStorage.setItem("laundryAddressId", response.data.id);
        window.location.reload();
      }
    } catch (err) {
      console.error("add new order err : ", err);
    } finally {
      setAddressLoading(false);
    }
  };

  const getLaundryItems = async () => {
    try {
      setItemsLoading(true);
      const response = await fetchLaundryItems();
      console.log(response);
      setItems(response.data);
    } catch (err) {
      console.error("get laundry items err : ", err);
    } finally {
      setItemsLoading(false);
    }
  };
  const getServices = async () => {
    try {
      setServicesLoading(true);
      const response = await fetchServices();
      console.log(response);
      setServices(response.data);
    } catch (err) {
      console.error("get services err : ", err);
    } finally {
      setServicesLoading(false);
    }
  };

  const handleItemChange = (e, itemId) => {
    const isChecked = e.target.checked;

    setData((prevData) => {
      let updatedItems;

      if (isChecked) {
        // If the item is not already in the list, add it
        if (!prevData.items.some((item) => item.laundry_item_id === itemId)) {
          updatedItems = [
            ...prevData.items,
            { laundry_item_id: itemId, quantity: 1, services: [] },
          ];
        } else {
          // If it's already in the list, keep the existing items
          updatedItems = prevData.items;
        }
      } else {
        // If unchecked, remove the item
        updatedItems = prevData.items.filter(
          (item) => item.laundry_item_id !== itemId
        );
      }

      return { ...prevData, items: updatedItems };
    });
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    setData((prevData) => ({
      ...prevData,
      items: prevData.items.map((item) =>
        item.laundry_item_id === itemId
          ? { ...item, quantity: newQuantity }
          : item
      ),
    }));
  };

  const handleServiceChange = (e, serviceId, itemId) => {
    const isChecked = e.target.checked;

    setData((prevData) => {
      const updatedItems = prevData.items.map((item) => {
        if (item.laundry_item_id === itemId) {
          const updatedServices = isChecked
            ? // Add the service if checked, ensuring no duplicates
              item.services.some(
                (service) =>
                  service.service_id === serviceId && service.item_id === itemId
              )
              ? item.services
              : [...item.services, { service_id: serviceId, item_id: itemId }]
            : // Remove the service if unchecked
              item.services.filter(
                (service) =>
                  !(
                    service.service_id === serviceId &&
                    service.item_id === itemId
                  )
              );

          return { ...item, services: updatedServices };
        }
        return item;
      });

      return { ...prevData, items: updatedItems };
    });
  };

  useEffect(() => {
    isSuper && getLaundriesData(0);
  }, []);
  useEffect(() => {
    getLaundryItems();
    getServices();
  }, [lng]);

  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <div className="font-[500]">{t("Add new order")}</div>
      <div className="flex flex-col gap-3">
        {isSuper && (
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="laundry"
            >
              {t("Choose A Laundry")} <span className="text-red-600">*</span> :
            </label>
            <CustomSelect
              value={data.laundryId}
              name="laundryId"
              id="laundry"
              onChange={changeHandler}
              className={`w-full`}
              children={
                <>
                  <option disabled className="text-MainText" value="">
                    {t("Choose A Laundry")}
                  </option>
                  {laundries.map((laundry) => (
                    <option
                      className="text-MainText"
                      key={laundry.id}
                      value={laundry.id}
                    >
                      {selectedLanguage === "Arabic"
                        ? laundry.name_ar
                        : laundry.name_en}
                    </option>
                  ))}
                </>
              }
            />
          </div>
        )}
        <div className="flex flex-col gap-2">
          <label className="text-[13px] flex items-center gap-1 cursor-pointer">
            {t("Address")} <span className="text-red-600">*</span> :
          </label>
          <div
            onClick={() => setShowAddress(!showAddress)}
            className="md:w-fit bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 min-h-[38.34px]"
          >
            {showAddress ? t("I do not need to add address") : t("Add address")}
          </div>
        </div>
        <form
          onSubmit={submitAddressHandler}
          className={`${
            showAddress ? "h-full opacity-100" : "h-[0px] opacity-0 -z-10"
          } transition-all duration-300 flex flex-col gap-5 w-full`}
        >
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="addressLine1"
              >
                {t("Address line 1")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={address.addressLine1}
                name="addressLine1"
                onChange={changeAddressHandler}
                id="addressLine1"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Address line 1")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="addressLine2"
              >
                {t("Address line 2")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={address.addressLine2}
                name="addressLine2"
                onChange={changeAddressHandler}
                id="addressLine2"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Address line 2")}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="fullName"
              >
                {t("Full name")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={address.fullName}
                name="fullName"
                onChange={changeAddressHandler}
                id="fullName"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Full name")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="city"
              >
                {t("City")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={address.city}
                name="city"
                onChange={changeAddressHandler}
                id="city"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("City")}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="country"
              >
                {t("Country")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={address.country}
                name="country"
                onChange={changeAddressHandler}
                id="country"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Country")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="postCode"
              >
                {t("Postal code")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={address.postCode}
                name="postCode"
                onChange={changeAddressHandler}
                id="postCode"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Postal code")}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="contactNumber"
              >
                {t("Contact number")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={address.contactNumber}
                name="contactNumber"
                onChange={changeAddressHandler}
                id="contactNumber"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Contact number")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="address"
              >
                {t("Address")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={address.address}
                name="address"
                onChange={changeAddressHandler}
                id="address"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Address")}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="lat"
              >
                {t("Lat")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={address.lat}
                name="lat"
                onChange={changeAddressHandler}
                id="lat"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Lat")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="lng"
              >
                {t("Lng")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={address.lng}
                name="lng"
                onChange={changeAddressHandler}
                id="lng"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Lng")}
              />
            </div>
          </div>
          <button
            type="submit"
            onClick={submitAddressHandler}
            className={`${
              addressLoading
                ? "cursor-default pointer-events-none"
                : "cursor-pointer"
            } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
          >
            {addressLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              t("Add")
            )}
          </button>
        </form>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="name"
            >
              {t("Name")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.name}
              name="name"
              onChange={changeHandler}
              id="name"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="email"
            >
              {t("email")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.email}
              name="email"
              onChange={changeHandler}
              id="email"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("email")}
            />
          </div>
        </div>
        {/* <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="date"
            >
              {t("Date")}
              <span className="text-red-600">*</span> :
            </label>
            <input
              type="date"
              value={data.date}
              name="date"
              onChange={changeHandler}
              id="date"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer"
              placeholder={t("Date")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="time"
            >
              {t("Time")}
              <span className="text-red-600">*</span> :
            </label>
            <input
              type="time"
              value={data.time}
              name="time"
              onChange={changeHandler}
              id="time"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer"
              placeholder={t("Time")}
            />
          </div>
        </div> */}
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="note"
            >
              {t("Note")}
              <span className="text-red-600">*</span> :
            </label>
            <textarea
              value={data.note}
              name="note"
              onChange={changeHandler}
              id="note"
              className="resize-none max-h-[100px] py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[100px]"
              placeholder={t("Note")}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="items"
          >
            {t("Select Items")} <span className="text-red-600">*</span> :
          </label>
          {itemsLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div id="items" className="flex flex-col gap-2 text-[13px] w-full">
              {items.map((item) => {
                const selectedItem = data.items.find(
                  (selectedItem) => selectedItem.laundry_item_id === item.id
                );
                const isChecked = Boolean(selectedItem);
                console.log(isChecked);
                return (
                  <div
                    className="text-MainText bg-white flex flex-col gap-2 shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                    key={item.id}
                  >
                    <div className="flex items-center gap-2">
                      <input
                        id={`item-${item.id}`}
                        type="checkbox"
                        className="focus:ring-0"
                        checked={isChecked}
                        onChange={(e) => handleItemChange(e, item.id)}
                      />
                      <label htmlFor={`item-${item.id}`}>
                        {lng === "Arabic"
                          ? item.item_type_ar
                          : item.item_type_en}
                      </label>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label
                        className="text-[13px] flex items-center gap-1 cursor-pointer"
                        htmlFor={`services${item.id}`}
                      >
                        {t("Select Services")}
                        <span className="text-red-600">*</span> :
                      </label>
                      {servicesLoading ? (
                        <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
                      ) : (
                        <div
                          id={`services${item.id}`}
                          className="grid grid-cols-2 max-md:grid-cols-1 gap-3 text-[13px] w-full"
                        >
                          {services.map((service) => {
                            const isServiceSelected =
                              selectedItem?.services.some(
                                (s) => s.service_id === service.id
                              );
                            return (
                              <div
                                className="text-MainText bg-white flex justify-between items-center shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                                key={service.id}
                              >
                                <div className="flex items-center gap-2">
                                  <input
                                    id={`service-${service.id}`}
                                    type="checkbox"
                                    className="focus:ring-0"
                                    checked={isServiceSelected}
                                    disabled={!isChecked}
                                    onChange={(e) =>
                                      handleServiceChange(
                                        e,
                                        service.id,
                                        item.id
                                      )
                                    }
                                  />
                                  <label htmlFor={`service-${service.id}`}>
                                    {lng === "Arabic"
                                      ? service.name_ar
                                      : service.name_en}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className="flex items-center gap-2 mt-2">
                        <label htmlFor={`quantity-${item.id}`}>
                          {t("Quantity")}
                        </label>
                        <input
                          className="focus:ring-0 text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 outline-none px-2 py-1 rounded"
                          id={`quantity-${item.id}`}
                          type="number"
                          value={selectedItem?.quantity || ""}
                          disabled={!isChecked}
                          onChange={(e) =>
                            handleQuantityChange(
                              item.id,
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading || showAddress
              ? "cursor-default pointer-events-none opacity-70"
              : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Add")
          )}
        </button>
      </div>
    </form>
  );
}

export default AddNewOrder;
