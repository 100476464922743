import React, { useState } from "react";
import OutterTable from "./OutterTable";
import MainHeadTableItem from "./MainHeadTableItem";
import MainBodyTableItem from "./MainBodyTableItem";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import InnerTable from "./InnerTable";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMdMore } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";

function ProductsData({ products, loading, productsPage }) {
  const { t } = useTranslation();
  const [moreOpen, setMoreOpen] = useState({});

  const toggleMoreOpen = (itemId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[itemId] = !prevState[itemId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== itemId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };
  return (
    <OutterTable>
      <thead>
        <tr>
          <MainHeadTableItem
            content={t("English Name")}
            className={`w-[120px]`}
          />
          <MainHeadTableItem
            content={t("Arabic Name")}
            className={`max-md:hidden w-[120px]`}
          />
          <MainHeadTableItem
            content={t("Image")}
            className={`max-md:hidden w-[125px]`}
          />
          <MainHeadTableItem
            content={t("Details")}
            className={`max-md:hidden`}
          />

          <MainHeadTableItem content={`...`} className={`md:hidden w-[70px]`} />
        </tr>
      </thead>
      <tbody>
        {loading
          ? Array.from({ length: 10 }).map((_, outIndex) => (
              <tr key={outIndex}>
                {Array.from({ length: 4 }).map((_, inIndex) => (
                  <td
                    key={inIndex}
                    className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                  >
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ))
          : products?.map((item, index) => (
              <>
                <tr key={index}>
                  <MainBodyTableItem
                    content={item?.item?.en}
                    className={`w-[120px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={item?.item?.ar}
                    className={`max-md:hidden w-[120px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={
                      <img
                        className="w-[100px] h-[100px] max-w-[100px] max-h-[100px] mx-auto object-cover"
                        src={item?.item?.url_image}
                        alt=""
                      />
                    }
                    className={`max-md:hidden w-[125px] overflow-x-auto`}
                  />
                  <td className="border border-[#9ea5ad] text-[13px] text-center max-md:hidden">
                    <table className="w-full h-full">
                      <thead>
                        <tr>
                          <th className="border border-[#9ea5ad] p-[6px] text-[11px]">
                            {t("Service Name")}
                          </th>
                          <th className="border border-[#9ea5ad] p-[6px] text-[11px]">
                            {t("Direct Price")}
                          </th>
                          {productsPage && (
                            <th className="border border-[#9ea5ad] p-[6px] text-[11px] w-[40px]">
                              <IoMdMore className={`mx-auto`} />
                            </th>
                          )}
                          <th className="border border-[#9ea5ad] p-[6px] text-[11px]">
                            {t("Indirect Price")}
                          </th>
                          {productsPage && (
                            <th className="border border-[#9ea5ad] p-[6px] text-[11px] w-[40px]">
                              <IoMdMore className={`mx-auto`} />
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {item.services.map((serviceDetail) => (
                          <tr key={serviceDetail.service.id}>
                            <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px]">
                              {serviceDetail.service.en}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px]">
                              {serviceDetail.prices[0][0]} $
                            </td>
                            {productsPage && (
                              <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px] w-[40px]">
                                <Link
                                  to={`${item.item.id}/1/${serviceDetail.service.id}`}
                                  className="flex justify-center items-center"
                                >
                                  <AiOutlineEdit
                                    className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                  />
                                </Link>
                              </td>
                            )}
                            <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px]">
                              {serviceDetail.prices[1]
                                ? serviceDetail.prices[1][0]
                                : "N/A"}{" "}
                              $
                            </td>
                            {productsPage && (
                              <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px] w-[40px]">
                                <Link
                                  to={`${item.item.id}/2/${serviceDetail.service.id}`}
                                  className="flex justify-center items-center"
                                >
                                  <AiOutlineEdit
                                    className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                  />
                                </Link>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <MainBodyTableItem
                    content={
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(item?.item?.id)}
                      >
                        {moreOpen[item?.item?.id] ? (
                          <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                        ) : (
                          <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                        )}
                      </span>
                    }
                    className={`md:hidden w-[70px]`}
                  />
                </tr>
                <InnerTable openStatus={moreOpen[item?.item?.id]}>
                  <tbody>
                    <tr>
                      <MainHeadTableItem content={t("English Name")} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={
                          loading ? (
                            <Skeleton
                              width={"100%"}
                              className="h-[55px] max-md:h-[25px]"
                            />
                          ) : (
                            item?.item?.en
                          )
                        }
                      />
                    </tr>
                    <tr>
                      <MainHeadTableItem content={t("Arabic Name")} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={
                          loading ? (
                            <Skeleton
                              width={"100%"}
                              className="h-[55px] max-md:h-[25px]"
                            />
                          ) : (
                            item?.item?.ar
                          )
                        }
                      />
                    </tr>
                    <tr>
                      <MainHeadTableItem content={t("Image")} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={
                          loading ? (
                            <Skeleton
                              width={"100%"}
                              className="h-[55px] max-md:h-[25px]"
                            />
                          ) : (
                            <img
                              className="w-[100px] h-[100px] max-w-[100px] max-h-[100px] mx-auto object-cover"
                              src={item?.item?.url_image}
                              alt=""
                            />
                          )
                        }
                      />
                    </tr>
                    <div className="block innerTable">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th className="border border-[#9ea5ad] p-[6px] text-[11px]">
                              {t("Service Name")}
                            </th>
                            <th className="border border-[#9ea5ad] p-[6px] text-[11px]">
                              {t("Direct Price")}
                            </th>
                            {productsPage && (
                              <th className="border border-[#9ea5ad] p-[6px] text-[11px] w-[40px]">
                                <IoMdMore className={`mx-auto`} />
                              </th>
                            )}
                            <th className="border border-[#9ea5ad] p-[6px] text-[11px]">
                              {t("Indirect Price")}
                            </th>
                            {productsPage && (
                              <th className="border border-[#9ea5ad] p-[6px] text-[11px] w-[40px]">
                                <IoMdMore className={`mx-auto`} />
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {item.services.map((serviceDetail) => (
                            <tr key={serviceDetail.service.id}>
                              <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px]">
                                {serviceDetail.service.en}
                              </td>
                              <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px]">
                                {serviceDetail.prices[0][0]} $
                              </td>
                              {productsPage && (
                                <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px] w-[40px]">
                                  <Link
                                    to={`${item.item.id}/1/${serviceDetail.service.id}`}
                                    className="flex justify-center items-center"
                                  >
                                    <AiOutlineEdit
                                      className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                    />
                                  </Link>
                                </td>
                              )}
                              <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px]">
                                {serviceDetail.prices[1]
                                  ? serviceDetail.prices[1][0]
                                  : "N/A"}{" "}
                                $
                              </td>
                              {productsPage && (
                                <td className="border border-[#9ea5ad] text-center p-[6px] text-[11px] w-[40px]">
                                  <Link
                                    to={`${item.item.id}/2/${serviceDetail.service.id}`}
                                    className="flex justify-center items-center"
                                  >
                                    <AiOutlineEdit
                                      className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                    />
                                  </Link>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </tbody>
                </InnerTable>
              </>
            ))}
      </tbody>
    </OutterTable>
  );
}

export default ProductsData;
