import { apiAdmin } from "./api";


export const fetchAllCars = async (page,search,laundry_id) => {
  try {
    const response = laundry_id ? await apiAdmin.get(`/api/cars?search=${search}&page=${page}&laundry_id=${laundry_id}`) : await apiAdmin.get(`/api/cars?search=${search}&page=${page}`)
    return response.data;
  } catch (error) {
    console.log("fetch all cars err : ", error);
  }
};

export const fetchCarsByLaundry = async (laundry_id) => {
  try {
    const response = await apiAdmin.get(`/api/CarByLaundry?laundry_id=${laundry_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch cars by laundry err : ", error);
  }
};

export const fetchCarById = async (car_id) => {
  try {
    const response = await apiAdmin.get(`/api/CarById?id=${car_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch car by id err : ", error);
  }
};

export const addCar = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/Car`,data);
    return response.data;
  } catch (error) {
    console.log("add car err : ", error);
  }
};

export const updateCar = async (car_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/updateCar?id=${car_id}`,data);
    return response.data;
  } catch (error) {
    console.log("update car err : ", error);
  }
};

export const updateCarStatus = async (car_id) => {
  try {
    const response = await apiAdmin.post(`/api/UpdateStatusCar?id=${car_id}`);
    return response.data;
  } catch (error) {
    console.log("update car status err : ", error);
  }
};

export const searchForCar = async (query) => {
  try {
    const response = await apiAdmin.get(`/api/search-cars?number=${query}`);
    return response.data;
  } catch (error) {
    console.log("search for car err : ", error);
  }
};