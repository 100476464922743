import React, { useState, useEffect } from "react";
import OutterTable from "../Components/OutterTable";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import { useTranslation } from "react-i18next";
import { useDashboard } from "../Context/DashboardContext";
import Skeleton from "react-loading-skeleton";
import InnerTable from "../Components/InnerTable";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";
import { usePopup } from "../Context/PopupContext";
import { fetchServices } from "../Api/Services";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";

function Services() {
  const { setMakeDecisionOpen, setAction, setType, setItemId } = usePopup();
  const { updateServices } = useDashboard();
  const [moreOpen, setMoreOpen] = useState({});
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const { t } = useTranslation();

  const getItems = async () => {
    try {
      setLoading(true);
      const response = await fetchServices();
      console.log(response);
      setServices(response.data);
    } catch (err) {
      console.error("get services err : ", err);
    } finally {
      setLoading(false);
    }
  };
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  useEffect(() => {
    getItems();
  }, [updateServices]);
  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <Link
        to={`addNewService`}
        className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[114px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
      >
        {t("Add new service")}
      </Link>
      {/* <div className="flex md:items-center max-md:flex-col gap-3">
        <SearchInput
          query={query}
          setQuery={setQuery}
          search={search}
          placeHolder={t("Search by admin name")}
          loading={searchloading}
        />
      </div> */}
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem content={t("English Name")} />
              <MainHeadTableItem
                content={t("Arabic Name")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Image")}
                className={`max-md:hidden w-[125px]`}
              />
              <MainHeadTableItem
                content={t("...")}
                className={`max-md:hidden w-[100px]`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: 5 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : services.length > 0 ? (
              services.map((service) => (
                <>
                  <tr key={service.id}>
                    <MainBodyTableItem
                      content={service.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem content={service.name_en} />
                    <MainBodyTableItem
                      content={service.name_ar}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <img
                          className="w-[100px] h-[100px] max-w-[100px] max-h-[100px] mx-auto object-cover"
                          src={service?.url_image}
                          alt=""
                        />
                      }
                      className={`max-md:hidden w-[125px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={
                        <div className="flex justify-center items-center mx-auto gap-1">
                          <Link
                            className={`text-[13px] cursor-pointer flex justify-center items-center`}
                            to={`${service.id}/updateService`}
                          >
                            <AiOutlineEdit
                              className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                            />
                          </Link>
                          <div
                            onClick={() => {
                              setMakeDecisionOpen(true);
                              setAction("delete");
                              setType("service");
                              setItemId(service.id);
                            }}
                            className={`text-[13px] cursor-pointer flex justify-center items-center`}
                          >
                            <AiFillDelete className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                          </div>
                        </div>
                      }
                      className={`max-md:hidden w-[100px]`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(service.id)}
                        >
                          {moreOpen[service.id] ? (
                            <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                          ) : (
                            <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                          )}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[service.id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={service.id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("English Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={service.name_en} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Arabic Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={service.name_ar} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Image")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <img
                              className="w-full h-full max-w-[200px] max-h-[250px] mx-auto object-cover"
                              src={service?.url_image}
                              alt=""
                            />
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("...")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <div className="flex justify-center items-center mx-auto gap-1">
                              <Link
                                className={`text-[13px] cursor-pointer flex justify-center items-center`}
                                to={`${service.id}/updateService`}
                              >
                                <AiOutlineEdit
                                  className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                />
                              </Link>
                              <div
                                onClick={() => {
                                  setMakeDecisionOpen(true);
                                  setAction("delete");
                                  setType("service");
                                  setItemId(service.id);
                                }}
                                className={`text-[13px] cursor-pointer flex justify-center items-center`}
                              >
                                <AiFillDelete className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                              </div>
                            </div>
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No services available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        {/* <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        /> */}
      </div>
    </div>
  );
}

export default Services;
