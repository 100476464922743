import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function Map({ latitude, langitude }) {
  const googleKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  console.log("googleKey is : ", googleKey);
  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(langitude),
  };
  console.log(center);

  return (
    <LoadScript googleMapsApiKey={googleKey}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={20}>
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;
