import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";

function SearchInput({ query, setQuery, search, placeHolder, loading }) {
  const lng = localStorage.getItem("dpLanguage") || "English";

  return (
    <div className="w-full relative md:max-w-[500px]">
      <input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className={`${
          lng !== "Arabic" ? "pr-5 pl-[12px]" : "pl-5 pr-[12px]"
        } py-[7.5px] shadow-lg text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]`}
        placeholder={placeHolder}
      />
      <div
        onClick={() => {
          !loading && search();
        }}
        className={`${
          lng === "Arabic"
            ? "left-0 rounded-l-[0.25rem]"
            : "right-0 rounded-r-[0.25rem]"
        } absolute cursor-pointer top-[50%] -translate-y-[50%] text-[13px] text-MainText bg-[#e2e2e2] h-full p-2 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          <FaSearch />
        )}
      </div>
    </div>
  );
}

export default SearchInput;
