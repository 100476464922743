import React, { useEffect, useState, useRef } from "react";
import { isSuper, lng } from "../Api/api";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";
import { fetchReports } from "../Api/Reports";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineArrowDropDown } from "react-icons/md";
import CustomSelect from "../Components/CustomSelect";

function Reports() {
  const { t } = useTranslation();
  const { laundries, getLaundriesData } = useDashboard();
  const [selectedLaundryId, setSelectedLaundryId] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const startRef = useRef(null);
  const endRef = useRef(null);

  const downloadReports = async () => {
    try {
      setLoading(true);
      const response = selectedLaundryId
        ? await fetchReports(startDate, endDate, selectedLaundryId)
        : await fetchReports(startDate, endDate);
      console.log(response);
      downloadFile(response.data, "report.xlsx");
    } catch (err) {
      console.error("download reports err : ", err);
    } finally {
      setLoading(false);
    }
  };
  const downloadFile = (data, filename) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    isSuper && getLaundriesData(0);
  }, []);
  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <div className="flex md:items-center max-md:flex-col gap-3">
        {isSuper && (
          <CustomSelect
            className={`md:min-w-[200px] md:max-w-[200px]`}
            value={selectedLaundryId}
            onChange={(e) => setSelectedLaundryId(e.target.value)}
            children={
              <>
                <option className="text-MainText" value="">
                  {t("All Laundries")}
                </option>
                {laundries.map((item) => (
                  <option key={item.laundry.id} value={item.laundry.id}>
                    {lng === "Arabic"
                      ? item?.laundry?.name_ar
                      : item?.laundry?.name_en}
                  </option>
                ))}
              </>
            }
          />
        )}
        <div
          className="md:min-w-[200px] md:max-w-[200px] min-h-[38.34px] w-fit bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300"
          onClick={() => {
            startRef.current.type = "date";
            startRef.current.focus();
          }}
        >
          <input
            ref={startRef}
            className="w-full h-full cursor-pointer outline-none"
            type="text"
            value={startDate || t("Start Date")}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div
          className="md:min-w-[200px] md:max-w-[200px] min-h-[38.34px] w-fit bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300"
          onClick={() => {
            endRef.current.type = "date";
            endRef.current.focus();
          }}
        >
          <input
            ref={endRef}
            className="w-full h-full cursor-pointer outline-none"
            type="text"
            value={endDate || t("End Date")}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div
          onClick={downloadReports}
          className={`${
            loading ? "cursor-default" : "cursor-pointer"
          } bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[100px] min-h-[38.34px] font-semibold h-fit flex justify-center items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Download")
          )}
        </div>
      </div>
    </div>
  );
}

export default Reports;
