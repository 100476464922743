import React, { useEffect, useState } from "react";
import { fetchLaundryById } from "../Api/Laundry";
import { Link, useParams } from "react-router-dom";
import { lng } from "../Api/api";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import DriversData from "../Components/DriversData";
import CarsData from "../Components/CarsData";
import OrderData from "../Components/OrderData";
import BreakDiv from "../Components/BreakDiv";
import ProductsData from "../Components/ProductsData";

function LaundryById() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [laundry, setLaundry] = useState({});
  const [loading, setLoading] = useState(true);

  const getLaundryDataById = async (id) => {
    try {
      setLoading(true);
      const response = await fetchLaundryById(id);
      console.log(response);
      setLaundry(response.data);
    } catch (err) {
      console.error("get laundry data by id err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLaundryDataById(id);
  }, [lng]);

  return (
    <div className="flex flex-col gap-3">
      <Link
        to={`updateLaundry`}
        className="bg-white outline-none focus:ring-0 w-fit min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
      >
        {t("Edit this laundry")}
      </Link>
      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] max-md:hidden">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("English Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Arabic Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Description In English")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Description In Arabic")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Phone Number")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                {Array.from({ length: 5 }).map((_, index) => (
                  <td key={index}>
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ) : (
              <tr>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.name_en}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.name_ar}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.description_en}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.description_ar}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.phone_number}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] md:hidden">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <tbody>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("English Name")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.name_en
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Arabic Name")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.name_ar
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("English Description")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.description_en
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Arabic Description")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.description_ar
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Phone Number")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.phone_number
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <BreakDiv />
      <div className="font-[500]">{t("Laundry Addresses")} : </div>
      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] max-md:hidden">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("City")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Address Line")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Lat,Lng")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                {Array.from({ length: 3 }).map((_, index) => (
                  <td key={index} className={`${index < 1 && "max-md:hidden"}`}>
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ) : (
              <>
                <tr>
                  <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                    {laundry?.addresses?.city}
                  </td>
                  <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                    {laundry?.addresses?.address_line_1}
                  </td>
                  <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                    {`(${laundry?.addresses?.lat},${laundry?.addresses?.lng})`}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] md:hidden">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <tbody>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("City")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry?.addresses?.city
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Address Line")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry?.addresses?.address_line_1
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Lat,Lng")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  `(${laundry?.addresses?.lat},${laundry?.addresses?.lng})`
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <BreakDiv />
      <div className="font-[500]">{t("Laundry Items With Services")} : </div>
      <ProductsData products={laundry?.details} loading={loading} />
      <BreakDiv />
      <div className="font-[500]">{t("Laundry Images")} : </div>
      {loading ? (
        <Skeleton className="min-w-full min-h-[300px] max-md:min-h-[200px]" />
      ) : (
        <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white w-fit">
          {laundry?.media?.map((pic, index) => (
            <div
              key={pic.id}
              className="max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[200px]"
            >
              <img
                className="w-full h-full object-cover"
                src={pic.url_image}
                alt={`image ${index}`}
              />
            </div>
          ))}
        </div>
      )}
      <BreakDiv />
      <div className="font-[500]">{t("Laundry Orders")} : </div>
      <OrderData laundryByIdPage={true} />
      <BreakDiv />
      <div className="font-[500]">{t("Laundry Drivers")} : </div>
      <DriversData laundryByIdPage={true} />
      <BreakDiv />
      <div className="font-[500]">{t("Laundry Cars")} : </div>
      <CarsData laundryByIdPage={true} />
    </div>
  );
}

export default LaundryById;
