import React, { useState } from "react";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { addLaundryItem } from "../Api/Items";
import { useTranslation } from "react-i18next";

function AddNewItem() {
  const { t } = useTranslation();
  const [showPicOptions, setShowPicOptions] = useState(false);

  const [data, setData] = useState({
    nameEn: "",
    nameAr: "",
    img: "",
  });
  const [loading, setLoading] = useState(false);

  const changeHandler = (e) => {
    setData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const imgHandler = (e) => {
    setData((data) => ({ ...data, [e.target.name]: e.target.files[0] }));
  };

  const deleteImage = () => {
    setData((data) => ({ ...data, img: "" }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("item_type_en", data.nameEn);
    formData.append("item_type_ar", data.nameAr);
    formData.append("url_image", data.img);
    try {
      setLoading(true);
      const response = await addLaundryItem(formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/items";
      }
    } catch (err) {
      console.error("add laundry item err : ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <div className="font-[500]">{t("Add New Item")}</div>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameEn"
            >
              {t("English Name")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameEn}
              name="nameEn"
              onChange={changeHandler}
              id="nameEn"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("English Name")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameAr"
            >
              {t("Arabic Name")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameAr}
              name="nameAr"
              onChange={changeHandler}
              id="nameAr"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Arabic Name")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="img"
            >
              {t("Image")} <span className="text-red-600">*</span> :
            </label>
            <input
              type="file"
              name="img"
              onChange={imgHandler}
              id="img"
              className="py-[7.5px] bg-white shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
            />
          </div>
        </div>
        {data.img !== "" && (
          <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white w-fit">
            <div
              onMouseOver={() => setShowPicOptions(true)}
              onMouseLeave={() => setShowPicOptions(false)}
              className="w-full relative max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[200px]"
            >
              <div
                className={`${
                  showPicOptions ? "md:block" : "md:hidden"
                } animate-flashing absolute left-0 top-0 w-full h-full bg-black/25 max-md:bg-transparent`}
              >
                <div
                  onClick={deleteImage}
                  className="mt-2 mx-2 w-fit cursor-pointer text-MainText flex justify-center items-center rounded-full p-2 bg-[#d8d7d7] hover:bg-[#5f5f5f] hover:text-white duration-300"
                >
                  <AiOutlineClose size={15} />
                </div>
              </div>
              <img
                className="w-full h-full object-cover"
                src={URL.createObjectURL(data.img)}
                alt={`image`}
              />
            </div>
          </div>
        )}
      </div>
      <button
        type="submit"
        onClick={submitHandler}
        className={`${
          loading ? "cursor-default pointer-events-none" : "cursor-pointer"
        } min-w-[81.38px] min-h-[38.34px] py-[7.5px] px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          t("Add")
        )}
      </button>
    </form>
  );
}

export default AddNewItem;
