import React, { useEffect, useState } from "react";
import { fetchOrderById } from "../Api/Orders";
import { useDashboard } from "../Context/DashboardContext";
import Skeleton from "react-loading-skeleton";
import { FaRegEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Pagination from "../Components/Pagination";
import { Link, useParams } from "react-router-dom";
import { isSuper } from "../Api/api";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

function OrderDetails() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";

  const { id } = useParams();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const [moreOpen, setMoreOpen] = useState({});

  // Function to toggle the open state for a specific order
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getOrderDetails = async () => {
    try {
      setLoading(true);
      const response = await fetchOrderById(id);
      console.log(response);
      setOrder(response.data);
    } catch (err) {
      console.error("get order by id err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <div className="font-[500]">{t("Order Details")}</div>
      {loading ? (
        <Skeleton className="rounded-[0.25rem] h-[200px]" />
      ) : (
        <div className="flex flex-col border border-[#9ea5ad] shadow-lg bg-white rounded-[0.25rem] p-[20px] text-[13px]">
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Number")} :</strong> {order?.order_number}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Source")} :</strong> {order?.type_order}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Type")} :</strong> {order?.order_type?.type}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Status")} :</strong> {order?.status}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Order Date")} :</strong> {order?.order_date}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Pickup Time")} :</strong> {order?.pickup_time}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Delivery Time")} :</strong> {order?.delivery_time}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Base Cost")} :</strong> {order?.base_cost} $
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Note")} :</strong> {order?.note}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Name")} :</strong> {order?.user?.name}
          </div>
          <div className="flex items-center gap-1 w-full whitespace-nowrap">
            <strong>{t("Email")} :</strong> {order?.user?.email}
          </div>
        </div>
      )}
      <OutterTable>
        <thead>
          <tr>
            <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
            <MainHeadTableItem content={t("Item")} />
            <MainHeadTableItem
              content={t("Image")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Quantity")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Price")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Sub Total Price")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Service Id")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem className={`md:hidden w-[70px]`} />
          </tr>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: 10 }).map((_, outIndex) => (
              <tr key={outIndex}>
                {Array.from({ length: 8 }).map((_, inIndex) => (
                  <td
                    key={inIndex}
                    className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                  >
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ))
          ) : order.order_items.length > 0 ? (
            order?.order_items?.map((item) => (
              <>
                <tr key={item?.id} className="">
                  <MainBodyTableItem
                    content={item?.id}
                    className={`w-[70px]`}
                  />
                  <MainBodyTableItem
                    content={
                      lng === "Arabic"
                        ? item?.laundry_item?.item_type_ar
                        : item?.laundry_item?.item_type_en
                    }
                  />
                  <MainBodyTableItem
                    content={
                      <img
                        className="w-[100px] h-[100px] max-w-[100px] max-h-[100px] mx-auto object-cover"
                        src={item?.laundry_item?.url_image}
                        alt=""
                      />
                    }
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={item?.quantity}
                    className={`max-md:hidden`}
                  />

                  <MainBodyTableItem
                    content={`${parseFloat(item?.price).toFixed(2)} $`}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={`${parseFloat(item?.sub_total_price).toFixed(
                      2
                    )} $`}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(item?.id)}
                      >
                        {moreOpen[item?.id] ? (
                          <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                        ) : (
                          <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                        )}
                      </span>
                    }
                    className={`md:hidden w-[70px]`}
                  />
                  <MainBodyTableItem
                    content={item?.service_id}
                    className={`max-md:hidden`}
                  />
                </tr>
                <InnerTable openStatus={moreOpen[item?.id]}>
                  <tbody>
                    <tr>
                      <MainBodyTableItem
                        content={t("Id")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={item?.id} />
                    </tr>
                    {isSuper && (
                      <tr>
                        <MainBodyTableItem
                          content={t("Item")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            lng === "Arabic"
                              ? item?.laundry_item?.item_type_ar
                              : item?.laundry_item?.item_type_en
                          }
                        />
                      </tr>
                    )}
                    <tr>
                      <MainBodyTableItem
                        content={t("Image")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={
                          <img
                            className="w-[100px] h-[100px] max-w-[100px] max-h-[100px] mx-auto object-cover"
                            src={item?.laundry_item?.url_image}
                            alt=""
                          />
                        }
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Quantity")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={item?.quantity} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Price")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={`${parseFloat(item?.price).toFixed(2)} $`}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Sub Total Price")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={`${parseFloat(item?.sub_total_price).toFixed(
                          2
                        )} $`}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Service Id")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={item?.service_id} />
                    </tr>
                  </tbody>
                </InnerTable>
              </>
            ))
          ) : (
            <div className="whitespace-nowrap p-[12px] text-[13px]">
              {t("No order available")}
            </div>
          )}
        </tbody>
      </OutterTable>
    </div>
  );
}

export default OrderDetails;
