import React, { createContext, useContext, useEffect, useState } from "react";
import { laundryByAdmin } from "../Api/Laundry";
import { adminToken, isSuper, superAdminToken } from "../Api/api";
import { fetchNotifacations, markAsRead } from "../Api/Notifactions";

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

function DashboardProvider({ children }) {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [updateProducts, setUpdateProducts] = useState(0);
  const [productId, setProductId] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [orderStatusId, setOrderStatusId] = useState(1);
  const [today, setToday] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(1);
  const [orderStatus, setOrderStatus] = useState("");
  const [updateOrders, setUpdateOrders] = useState(0);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState("");
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState("");
  const [updateLaundriesData, setUpdateLaudriesData] = useState(0);
  const [updateAds, setUpdateAds] = useState(0);
  const [updateDrivers, setUpdateDrivers] = useState(1);
  const [updateCars, setUpdateCars] = useState(1);
  const [updateAdPics, setUpdateAdPics] = useState(0);
  const [updateLaundryPics, setUpdateLaudriesPics] = useState(0);
  const [updateItems, setUpdateItems] = useState(0);
  const [updateServices, setUpdateServices] = useState(0);
  const [dir, setDir] = useState("ltr");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    try {
      setLoading(true);
      const response = await laundryByAdmin();
      console.log(response.data);
      setProducts(response.data.laundries[0]?.laundry.details);
      setLaundryName(
        lng === "Arabic"
          ? response.data.laundries[0]?.laundry.name_ar
          : response.data.laundries[0]?.laundry.name_en
      );
      localStorage.setItem("laundryId", response.data.laundries[0]?.laundry.id);
    } catch (err) {
      console.error("get prices err : ", err);
    } finally {
      setLoading(false);
    }
  };
  const [laundryName, setLaundryName] = useState();
  const handleInputsClick = (ref) => {
    if (ref.current) {
      ref.current.focus();
      // This is to open the date picker. However, not all browsers may support this.
      ref.current.showPicker?.();
    }
  };

  const [laundries, setLaundries] = useState([]);
  const [laundriesLoading, setLaundriesLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [paginationKey, setPaginationKey] = useState(0);

  const getLaundriesData = async (page) => {
    try {
      setLaundriesLoading(true);
      const response = await laundryByAdmin(page);
      console.log(response.data);
      if (page === 0) {
        setLaundries(response.data.laundries);
      } else {
        setLaundries(response.data.laundries);
        setCurrentPage(response.data.pagination.current_page);
        setTotalPages(response.data.pagination.last_page);
        setItemPerPage(response.data.pagination.per_page);
        setTotal(response.data.pagination.total);
        setFrom(response.data.pagination.from);
        setTo(response.data.pagination.to);
      }
    } catch (err) {
      console.error("get laundry data err : ", err);
    } finally {
      setLaundriesLoading(false);
    }
  };

  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [updateNotifications, setUpdateNotifications] = useState(0);

  const getNotifications = async (page, perPage) => {
    try {
      setNotificationsLoading(true);
      const response = await fetchNotifacations(page, perPage);
      console.log(response);
      setNotifications(response.data.notifications);
      setNotificationsCount(response.data.count);
      setCurrentPage(response?.data?.pagination?.current_page);
      setTotalPages(response?.data?.pagination?.last_page);
      setItemPerPage(response?.data?.pagination?.per_page);
      setTotal(response?.data?.pagination?.total);
      setFrom(response?.data?.pagination?.from);
      setTo(response?.data?.pagination?.to);
    } catch (err) {
      console.error("getNotifications err : ", err);
    } finally {
      setNotificationsLoading(false);
    }
  };

  const convertToRead = async (id) => {
    try {
      const response = await markAsRead(id);
      console.log(response);
      if (response.success === true) {
        setUpdateNotifications((prev) => prev + 1);
      }
    } catch (err) {
      console.error("convert notification to read err : ", err);
    }
  };

  const formatDate = (dateString) => {
    return dateString.replace("T", " ").split(".")[0];
  };

  useEffect(() => {
    window.location.pathname === "/dashboard/notifications"
      ? (adminToken || superAdminToken) && getNotifications(currentPage, 20)
      : (adminToken || superAdminToken) && getNotifications();
  }, [updateNotifications]);

  useEffect(() => {
    window.location.pathname != "/" && !isSuper && getProducts();
  }, [updateProducts, lng]);

  const contextValue = {
    updateProducts,
    setUpdateProducts,
    productId,
    setProductId,
    productPrice,
    setProductPrice,
    orderStatusId,
    setOrderStatusId,
    today,
    setToday,
    loading,
    setLoading,
    products,
    setProducts,
    laundryName,
    setLaundryName,
    laundries,
    setLaundries,
    laundriesLoading,
    setLaundriesLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    itemPerPage,
    setItemPerPage,
    total,
    setTotal,
    from,
    setFrom,
    to,
    setTo,
    getLaundriesData,
    orderId,
    setOrderId,
    orderStatus,
    setOrderStatus,
    updateOrders,
    setUpdateOrders,
    selectedDeliveryDate,
    setSelectedDeliveryDate,
    selectedDeliveryTime,
    setSelectedDeliveryTime,
    updateLaundriesData,
    setUpdateLaudriesData,
    updateAds,
    setUpdateAds,
    paginationKey,
    setPaginationKey,
    updateDrivers,
    setUpdateDrivers,
    updateCars,
    setUpdateCars,
    updateAdPics,
    setUpdateAdPics,
    updateLaundryPics,
    setUpdateLaudriesPics,
    updateItems,
    setUpdateItems,
    updateServices,
    setUpdateServices,
    dir,
    setDir,
    selectedLanguage,
    setSelectedLanguage,
    handleInputsClick,
    notifications,
    setNotifications,
    notificationsCount,
    setNotificationsCount,
    updateNotifications,
    setUpdateNotifications,
    notificationsLoading,
    setNotificationsLoading,
    convertToRead,
    formatDate,
  };
  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
}

export default DashboardProvider;
