import React from "react";
import { usePopup } from "../Context/PopupContext";
import { useDashboard } from "../Context/DashboardContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NotificationsPopup({ Ref }) {
  const { t } = useTranslation();
  const { notificationsOpen } = usePopup();
  const { notifications, notificationsCount, convertToRead } = useDashboard();
  const lng = localStorage.getItem("dpLanguage") || "English";

  return (
    <div
      ref={Ref}
      className={`${notificationsOpen ? "block" : "hidden"} ${
        lng === "Arabic"
          ? "md:left-0 max-md:-left-3"
          : "md:right-0 max-md:-right-3"
      } rounded-[0.25rem] shadow-md animate-flashing absolute bg-white md:min-w-[350px] max-md:w-screen z-20`}
    >
      <div className="flex flex-col items-center">
        {notifications.map((notification) => (
          <Link
            to={`/dashboard/orders/${notification.order_id}`}
            onClick={() => {
              notification.read_at === null && convertToRead(notification.id);
            }}
            key={notification.id}
            className={`${
              notification.read_at === null
                ? "bg-white hover:bg-[#dadada] cursor-pointer hover:shadow-md"
                : "bg-[#dadada]"
            } flex flex-col p-2 w-full duration-300`}
          >
            <div className="font-bold">{notification.title}</div>
            <div className="text-[#777] text-[14px]">{notification.body}</div>
          </Link>
        ))}
        <Link
          className="text-[14px] py-1 font-semibold hover:text-PrimaryColor duration-300"
          to={`/dashboard/notifications`}
        >
          {t("See all")} ({notificationsCount})
        </Link>
      </div>
    </div>
  );
}

export default NotificationsPopup;
