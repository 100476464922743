import React from "react";

function InnerTable({ children, openStatus }) {
  return (
    <div
      className={`${
        openStatus ? "block opacity-100" : "hidden opacity-0"
      } animate-flashing transition-all duration-300 innerTable md:hidden`}
    >
      <table className={`w-full`}>{children}</table>
    </div>
  );
}

export default InnerTable;

