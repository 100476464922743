import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";
import {
  updateLaundryCollectionStatus,
  updateLaundryStatus,
  updateLaundryUgentStatus,
} from "../Api/Laundry";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import { Link } from "react-router-dom";
import Pagination from "../Components/Pagination";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import { IoMdMore } from "react-icons/io";
import CustomSelect from "../Components/CustomSelect";

function Laundries() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { t } = useTranslation();
  const [moreOpen, setMoreOpen] = useState({});

  const {
    dir,
    updateLaundriesData,
    setUpdateLaudriesData,
    laundries,
    laundriesLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    itemPerPage,
    total,
    from,
    to,
    getLaundriesData,
  } = useDashboard();

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const handleUpdateStatus = async (id) => {
    try {
      const response = await updateLaundryStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateLaudriesData((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update laundry status err : ", err);
    }
  };

  const handleUpdateUgent = async (id) => {
    try {
      const response = await updateLaundryUgentStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateLaudriesData((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update laundry ugent status err : ", err);
    }
  };

  const handleUpdateCollection = async (id) => {
    try {
      const response = await updateLaundryCollectionStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateLaudriesData((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update colection status err : ", err);
    }
  };

  useEffect(() => {
    getLaundriesData(currentPage);
  }, [dir, updateLaundriesData, currentPage]);

  console.log(laundries);

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <Link
        to={`addNewLaundry`}
        className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[122px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
      >
        {t("Add new laundry")}
      </Link>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem content={t("Name")} />
              <MainHeadTableItem
                content={t("Description")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Phone Number")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={`...`}
                className={`md:hidden w-[70px]`}
              />
              <MainHeadTableItem
                content={t("Direct Orders")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Collection Support")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Status")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Image")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={`...`}
                className={`max-md:hidden w-[70px]`}
              />
            </tr>
          </thead>
          <tbody>
            {laundriesLoading ? (
              Array.from({ length: 11 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: 9 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : laundries.length > 0 ? (
              laundries.map((laundry) => (
                <>
                  <tr key={laundry?.laundry?.id}>
                    <MainBodyTableItem
                      content={laundry.laundry.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem
                      content={
                        lng === "Arabic"
                          ? laundry.laundry.name_ar
                          : laundry.laundry.name_en
                      }
                    />
                    <MainBodyTableItem
                      content={
                        lng === "Arabic"
                          ? laundry.laundry.description_ar
                          : laundry.laundry.description_en
                      }
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={laundry.laundry.phone_number}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(laundry.laundry.id)}
                        >
                          {moreOpen[laundry.laundry.id] ? (
                            <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                          ) : (
                            <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                          )}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                    <MainBodyTableItem
                      content={
                        <CustomSelect
                          className="mx-auto"
                          onChange={() => handleUpdateUgent(laundry.laundry.id)}
                          value={laundry?.laundry?.urgent === 1 ? 1 : 0}
                          children={
                            <>
                              <option
                                className="bg-white text-MainText"
                                value={1}
                              >
                                {t("Support")}
                              </option>
                              <option
                                className="bg-white text-MainText"
                                value={0}
                              >
                                {t("Does not support")}
                              </option>
                            </>
                          }
                        />
                      }
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <CustomSelect
                          className="mx-auto"
                          onChange={() =>
                            handleUpdateCollection(laundry.laundry.id)
                          }
                          value={laundry?.laundry?.collection === 1 ? 1 : 0}
                          children={
                            <>
                              <option
                                className="bg-white text-MainText"
                                value={1}
                              >
                                {t("Support")}
                              </option>
                              <option
                                className="bg-white text-MainText"
                                value={0}
                              >
                                {t("Does not support")}
                              </option>
                            </>
                          }
                        />
                      }
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <CustomSelect
                          className="mx-auto"
                          onChange={() =>
                            handleUpdateStatus(laundry?.laundry?.id)
                          }
                          value={
                            laundry?.laundry?.isActive === 1
                              ? "Active"
                              : "Not active"
                          }
                          children={
                            <>
                              <option
                                className="bg-white text-MainText"
                                value="Active"
                              >
                                {t("Active")}
                              </option>
                              <option
                                className="bg-white text-MainText"
                                value="Not active"
                              >
                                {t("Not active")}
                              </option>
                            </>
                          }
                        />
                      }
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <img
                          className="w-full h-full max-w-[200px] max-h-[150px] mx-auto object-cover"
                          src={laundry?.laundry?.media[0]?.url_image}
                          alt=""
                        />
                      }
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <Link
                          to={`${laundry?.laundry?.id}`}
                          className="flex justify-center items-center mx-auto"
                        >
                          <IoMdMore
                            className={`cursor-pointer hover:text-HoverPrimaryColor duration-300`}
                          />
                        </Link>
                      }
                      className={`max-md:hidden w-[70px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[laundry?.laundry?.id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={laundry?.laundry?.id}
                          className={`font-bold`}
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            lng === "Arabic"
                              ? laundry?.laundry?.name_ar
                              : laundry?.laundry?.name_en
                          }
                          className={`font-bold`}
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Description")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            lng === "Arabic"
                              ? laundry?.laundry?.description_ar
                              : laundry?.laundry?.description_en
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Phone Number")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={laundry?.laundry?.phone_number}
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Collection Support")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <CustomSelect
                              className="mx-auto"
                              onChange={() =>
                                handleUpdateCollection(laundry?.laundry?.id)
                              }
                              value={laundry?.laundry?.collection === 1 ? 1 : 0}
                              children={
                                <>
                                  <option
                                    className="bg-white text-MainText"
                                    value={1}
                                  >
                                    {t("Support")}
                                  </option>
                                  <option
                                    className="bg-white text-MainText"
                                    value={0}
                                  >
                                    {t("Does not support")}
                                  </option>
                                </>
                              }
                            />
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Direct Orders")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <CustomSelect
                              className="mx-auto"
                              onChange={() =>
                                handleUpdateUgent(laundry?.laundry?.id)
                              }
                              value={laundry?.laundry?.urgent === 1 ? 1 : 0}
                              children={
                                <>
                                  <option
                                    className="bg-white text-MainText"
                                    value={1}
                                  >
                                    {t("Support")}
                                  </option>
                                  <option
                                    className="bg-white text-MainText"
                                    value={0}
                                  >
                                    {t("Does not support")}
                                  </option>
                                </>
                              }
                            />
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Status")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <CustomSelect
                              className="mx-auto"
                              onChange={() =>
                                handleUpdateStatus(laundry?.laundry?.id)
                              }
                              value={
                                laundry?.laundry?.isActive === 1
                                  ? "Active"
                                  : "Not active"
                              }
                              children={
                                <>
                                  {" "}
                                  <option
                                    className="bg-white text-MainText"
                                    value="Active"
                                  >
                                    {t("Active")}
                                  </option>
                                  <option
                                    className="bg-white text-MainText"
                                    value="Not active"
                                  >
                                    {t("Not active")}
                                  </option>
                                </>
                              }
                            />
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Image")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <img
                              className="w-full h-full max-w-[200px] max-h-[150px] object-cover mx-auto"
                              src={laundry?.laundry?.media[0]?.url_image}
                              alt=""
                            />
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={`...`}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <Link
                              to={`${laundry?.laundry?.id}`}
                              className="w-fit mx-auto flex justify-center items-center"
                            >
                              <IoMdMore
                                className={`cursor-pointer hover:text-HoverPrimaryColor duration-300`}
                              />
                            </Link>
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No laundries available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Laundries;
