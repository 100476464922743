import React from "react";

function OutterTable({ children }) {
  return (
    <div className="z-0 border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem]">
      <table
        className={`bg-white text-MainText w-full table-fixed max-w-[screen] overflow-x-auto`}
      >
        {children}
      </table>
    </div>
  );
}

export default OutterTable;
