import React from "react";
import { useDashboard } from "../Context/DashboardContext";
import ProductsData from "../Components/ProductsData";

function Products() {
  const { products, loading } = useDashboard();

  return (
    <ProductsData products={products} loading={loading} productsPage={true} />
  );
}

export default Products;
