// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getMessaging, getToken } from "firebase/messaging"
import { getDatabase, ref, set, onValue, off } from "firebase/database";
import Cookies from "js-cookie";


// Your web app's Firebase configuration
const firebaseConfig = {
apiKey: "AIzaSyBXnD8Pq7LNIdlMaTpPhJCu_jH5tBaOtPU",
  authDomain: "dopepro-7aae6.firebaseapp.com",
  databaseURL: "https://dopepro-7aae6-default-rtdb.firebaseio.com",
  projectId: "dopepro-7aae6",
  storageBucket: "dopepro-7aae6.firebasestorage.app",
  messagingSenderId: "1091206966265",
  appId: "1:1091206966265:web:fda2dd7a9c9483d827ab28",
  measurementId: "G-NCZGVXE63B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const database = getDatabase(app);
console.log("database : ",database)


export const generateToken = async () => {
    const permession = await Notification.requestPermission()
    console.log("permission is : ", permession)
    if (permession === "granted") {
        try {

            const token =  await getToken(messaging, {
                 vapidKey: "BHDfIgDLXefyYQqurrpfmN9RbNKZSldEu0etXUEVhcJod6ypSsgsmhhSsJLNXdHxbOZmbfh89T1yAQuHGVPXLvE"
            })
            console.log(token)
            Cookies.set("dpNotificationToken", token)
        }
        catch (err) {
            console.error("generate token error is : ",err)
        }
    }
}

// Function to add or update a laundry car
export const updateLaundryCar = (carId, data) => {
  set(ref(database, 'laundryCars/' + carId), {
    ...data,
    lastUpdated: Date.now()
  });
}

// Function to get real-time updates for a laundry car
export const subscribeLaundryCar = (carId, callback) => {
  const carRef = ref(database, 'laundryCars/' + carId);
  onValue(carRef, (snapshot) => {
    const data = snapshot.val();
    callback(data);
  });

  // Return a function to unsubscribe
  return () => off(carRef);
}

// Function to get all laundry cars
export const getAllLaundryCars = (callback) => {
  const carsRef = ref(database, 'laundryCars');
  onValue(carsRef, (snapshot) => {
    const data = snapshot.val();
    callback(data);
  });

  // Return a function to unsubscribe
  return () => off(carsRef);
}
