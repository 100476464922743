import React, { useRef } from "react";
import DashboardSidebar from "../Components/DashboardSidebar";
import { MdOutlineMenu } from "react-icons/md";
import { Outlet } from "react-router-dom";
import { usePopup } from "../Context/PopupContext";
import Popup from "../Components/Popup";
import MakeDecision from "../Components/MakeDecision";
import SelectLanguage from "../Components/SelectLanguage";
import { useDashboard } from "../Context/DashboardContext";
import Skeleton from "react-loading-skeleton";
import { isSuper } from "../Api/api";
import { useTranslation } from "react-i18next";
import { IoIosNotificationsOutline } from "react-icons/io";
import NotificationsPopup from "../Components/NotificationsPopup";
import useClickOutside from "../hooks/useClickOutside";

function Dashboard() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const {
    dashboardSideBarOpen,
    setDashboardSideBarOpen,
    notificationsOpen,
    setNotificationsOpen,
  } = usePopup();
  const { loading, laundryName, notificationsCount } = useDashboard();
  const popupRef = useRef(null);

  useClickOutside(popupRef, () => setNotificationsOpen(false));

  return (
    <div className="flex bg-[#d8ddde]">
      <div className="max-md:hidden">
        <DashboardSidebar />
      </div>
      <div className="md:hidden">
        <Popup
          toggleSwitch={dashboardSideBarOpen}
          children={<DashboardSidebar mob={true} />}
          className={`${
            lng !== "Arabic"
              ? "animate-slideFromLeft"
              : "animate-slideFromRight"
          } bg-gradient-to-b from-[#78bcd0] to-[#00238c] w-full h-screen fixed z-30 overflow-hidden p-4`}
          onClose={() => setDashboardSideBarOpen(false)}
        />
      </div>
      <div className="flex flex-col w-full min-h-screen">
        <div className="sticky top-0 left-0 z-10 flex gap-2 px-3 items-center bg-white min-h-[58px] shadow-lg">
          <div className="cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300 p-2 rounded-full bg-[#e2e2e2] hover:bg-[#c8c8c8] md:hidden">
            <MdOutlineMenu
              onClick={() => setDashboardSideBarOpen(true)}
              size={20}
            />
          </div>
          <div
            className={`flex justify-between items-center gap-2 py-1 w-full`}
          >
            <div className="text-[18px] font-bold">
              {isSuper ? (
                t("Super Admin")
              ) : loading ? (
                <Skeleton width={150} height={20} />
              ) : (
                laundryName
              )}
            </div>
            <div
              ref={popupRef}
              onClick={() => {
                parseInt(notificationsCount) !== 0 &&
                  setNotificationsOpen(!notificationsOpen);
              }}
              className={`${
                parseInt(notificationsCount) !== 0 && "cursor-pointer"
              } relative`}
            >
              <IoIosNotificationsOutline className="text-[25px]" />
              {parseInt(notificationsCount) !== 0 && (
                <div className="bg-red-600 absolute -top-1 -left-1 rounded-full flex justify-center items-center w-[20px] h-[20px] text-[10px] text-white">
                  {parseInt(notificationsCount) > 99
                    ? "+99"
                    : notificationsCount}
                </div>
              )}
              <NotificationsPopup Ref={popupRef} />
            </div>
          </div>
        </div>
        <div className="p-3">
          <Outlet />
        </div>
      </div>
      <MakeDecision />
      <SelectLanguage />
    </div>
  );
}

export default Dashboard;
