import { apiAdmin } from "./api";

export const fetchProducts = async (laundry_id) => {
  try {
    const response = await apiAdmin.get(`/api/laundryItem?laundry_id=${laundry_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch products by laundry id err : ", error);
  }
};

export const fetchProductById = async (laundry_id,item_id,order_type_id,service_id) => {
  try {
    const response = await apiAdmin.get(`/api/laundryItemById?id=${item_id}&laundry_id=${laundry_id}&order_type_id=${order_type_id}&service_id=${service_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch products by laundry id err : ", error);
  }
};