import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Pagination from "../Components/Pagination";
import { fetchAllCars, updateCarStatus } from "../Api/Cars";
import { useDashboard } from "../Context/DashboardContext";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import { isSuper } from "../Api/api";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import { IoMdMore } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import CustomSelect from "./CustomSelect";

function CarsData({ laundryByIdPage }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);
  const { updateCars, setUpdateCars } = useDashboard();

  const search = async (e) => {
    getCarsData();
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const handleUpdateStatus = async (id) => {
    try {
      const response = await updateCarStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateCars((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update car status err : ", err);
    }
  };

  const getCarsData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAllCars(currentPage, query, id);
      console.log(response);
      setCars(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get cars data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };

  useEffect(() => {
    getCarsData();
  }, [updateCars, currentPage]);
  return (
    <div
      className={`${
        !laundryByIdPage && "max-md:min-h-screen"
      } flex gap-3 flex-col`}
    >
      <div className="flex md:items-center max-md:flex-col gap-3">
        {!laundryByIdPage && (
          <Link
            to={`addNewCar`}
            className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[97px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
          >
            {t("Add new car")}
          </Link>
        )}
        <SearchInput
          query={query}
          setQuery={setQuery}
          search={search}
          placeHolder={t("Search by car number")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem
                content={t("Car number")}
                className={`w-[110px]`}
              />
              <MainHeadTableItem
                content={t("Status")}
                className={`max-md:hidden w-[145px]`}
              />

              {isSuper && !laundryByIdPage && (
                <MainHeadTableItem
                  content={t("Laundry")}
                  className={`max-md:hidden`}
                />
              )}
              <MainHeadTableItem
                content={t("Driver name")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Lat")}
                className={`max-md:hidden w-[100px]`}
              />
              <MainHeadTableItem
                content={t("Lng")}
                className={`max-md:hidden w-[100px]`}
              />
              <MainHeadTableItem
                content={"..."}
                className={`max-md:hidden w-[60px]`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: isSuper ? 8 : 7 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : cars.length > 0 ? (
              cars.map((car) => (
                <>
                  <tr key={car.id} className="">
                    <MainBodyTableItem
                      content={car.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem
                      content={car.number_car}
                      className={`w-[110px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={
                        <CustomSelect
                          value={car.status === 1 ? "Active" : "Not active"}
                          onChange={() => handleUpdateStatus(car.id)}
                          className={`mx-auto`}
                          children={
                            <>
                              <option
                                className="bg-white text-MainText"
                                value="Active"
                              >
                                {t("Active")}
                              </option>
                              <option
                                className="bg-white text-MainText"
                                value="Not active"
                              >
                                {t("Not active")}
                              </option>
                            </>
                          }
                        />
                      }
                      className={`max-md:hidden`}
                    />

                    {isSuper && !laundryByIdPage && (
                      <MainBodyTableItem
                        content={
                          lng === "Arabic"
                            ? car?.laundry?.name_ar
                            : car?.laundry?.name_en
                        }
                        className={`max-md:hidden`}
                      />
                    )}
                    <MainBodyTableItem
                      content={car?.driver?.name}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={car.lat}
                      className={`max-md:hidden w-[100px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={car.lng}
                      className={`max-md:hidden w-[100px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(car.id)}
                        >
                          {moreOpen[car.id] ? (
                            <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                          ) : (
                            <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                          )}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                    <MainBodyTableItem
                      content={
                        <div className="flex justify-between items-center mx-auto">
                          <Link
                            to={`${car.id}/updateCar`}
                            className={`text-[13px] cursor-pointer flex justify-center items-center`}
                          >
                            <AiOutlineEdit
                              size={15}
                              className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                            />
                          </Link>
                          <Link
                            to={`${car.id}`}
                            className={`text-[13px] cursor-pointer flex justify-center items-center`}
                          >
                            <IoMdMore
                              className={`cursor-pointer hover:text-HoverPrimaryColor duration-300`}
                            />
                          </Link>
                        </div>
                      }
                      className={`max-md:hidden w-[60px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[car.id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Car number")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.number_car} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Status")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <CustomSelect
                              value={car.status === 1 ? "Active" : "Not active"}
                              onChange={() => handleUpdateStatus(car.id)}
                              className={`mx-auto`}
                              children={
                                <>
                                  <option
                                    className="bg-white text-MainText"
                                    value="Active"
                                  >
                                    {t("Active")}
                                  </option>
                                  <option
                                    className="bg-white text-MainText"
                                    value="Not active"
                                  >
                                    {t("Not active")}
                                  </option>
                                </>
                              }
                            />
                          }
                        />
                      </tr>

                      {isSuper && !laundryByIdPage && (
                        <tr>
                          <MainBodyTableItem
                            content={t("Laundry")}
                            className={`font-bold`}
                          />
                          <MainBodyTableItem
                            content={
                              lng === "Arabic"
                                ? car?.laundry?.name_ar
                                : car?.laundry?.name_en
                            }
                          />
                        </tr>
                      )}
                      <tr>
                        <MainBodyTableItem
                          content={t("Driver name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car?.driver?.name} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Lat")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.lat} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Lng")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.lng} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={"..."}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <div className="flex justify-center items-center gap-1 mx-auto">
                              <Link
                                to={`${car.id}/updateCar`}
                                className={`text-[13px] cursor-pointer flex justify-center items-center`}
                              >
                                <AiOutlineEdit
                                  size={15}
                                  className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                />
                              </Link>
                              <Link
                                to={`${car.id}`}
                                className={`text-[13px] cursor-pointer flex justify-center items-center`}
                              >
                                <IoMdMore
                                  className={`cursor-pointer hover:text-HoverPrimaryColor duration-300`}
                                />
                              </Link>
                            </div>
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No cars available")}
              </div>
            )}
          </tbody>
        </OutterTable>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default CarsData;
